import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Logo = () => {
    return (
        <Link to="/">
            <StaticImage
                src="../../data/images/lmlogo.png"
                alt="Lucky Machines"
            />
        </Link>
    );
};
export default Logo;
